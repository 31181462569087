import request from '@/utils/requestV2'

// 统计资源最后的换画距离现在的天数
export function countMaxDayNumberGbResource (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/countMaxDayNumberGbResource',
    method: 'get',
    params: data
  })
}
// 获取商业在刊的资源数并按分组
export function countIssueNumberGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/countIssueNumberGb',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 获取在刊的资源数并按合同类型分组
export function countIssueNumberGbFeature (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/countIssueNumberGbFeature',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 获取时间段内的各类型运维成本及占比
export function listMaintainBillGbType (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/listMaintainBillGbType',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 按天分组统计工单的完成情况
export function countTaskItemGbDate (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/countTaskItemGbDate',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 获取线路运维的故障率、修复率、成本占比、作业量占比和监播完成率的雷达图
export function getAssetMaintainRadar (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/getAssetMaintainRadar',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 统计供应商运维雷达图
export function getSupplierMaintainRadar (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/getSupplierMaintainRadar',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 统计时间段内品牌的按时完成率
export function countTaskItemGbGrand (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/countTaskItemGbGrand',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 获取业主的运维TOP5
export function getPublisherMaintainTopRateByType (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/getPublisherMaintainTopRateByType',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 查询线路特定日期按站台分组的作业量
export function listAssetFinishStationByDate (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintain/listAssetFinishStationByDate',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
