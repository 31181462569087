<template>
  <div>
    <Row>
      <i-col span="24">
        <Select v-model="chooseCompanyId">
            <Option v-for="item in childCompanyList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="p-t-5 text-center">
      <i-col span="24">
        <RadioGroup v-model="chooseFeature" type="button" button-style="solid">
            <Radio label="1" style="width:162px">线路</Radio>
            <Radio label="2" style="width:162px">供应商</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row v-if="chooseFeature==='1'" class="p-t-5 p-b-10">
      <i-col span="24">
        <Select v-model="chooseAssetId">
            <Option v-for="item in companyAssetList" :value="item.keyId" :key="item.keyId">{{ item.content }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row v-else class="p-t-5 p-b-10">
      <i-col span="24">
        <Select v-model="chooseSupplierId">
            <Option v-for="item in supplierList" :value="item.id" :key="item.id">{{item.name}}</Option>
        </Select>
      </i-col>
    </Row>
    <div style="width:290px;height:200px;" id="maintenanceRadarReport"></div>

    <h2 class="text-center" style="margin-top: -10px;">运维成本</h2>
    <Row class="table-title p-l-10 p-r-10" :gutter="8">
      <i-col span="6">类别</i-col>
      <i-col span="6">数量</i-col>
      <i-col span="6" class="text-right">成本</i-col>
      <i-col span="6" class="text-right">成本占比</i-col>
    </Row>
    <Row v-for="(item,index) in maintainBillGbTypeData" :key="'m'+index" :gutter="8" class="p-t-5 p-l-10 p-r-10">
      <i-col span="6">{{item.name}}</i-col>
      <i-col span="6">{{item.number}}</i-col>
      <i-col span="6" class="text-right">{{formatMoney(item.targetAmount)}}</i-col>
      <i-col span="6" class="text-right">{{item.rate}} %</i-col>
    </Row>
    <p v-if="maintainBillGbTypeData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <h2 class="text-center p-t-10">履约列表</h2>
    <Table class="maintenanceTable" size="small" :columns="countTaskItemGbDateColumn" :data="countTaskItemGbDateData" :row-class-name="rowClassName"
      @on-row-click="handleClickRow"></Table>
    <div class="paging_style">
        <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { toMoney } from '@/utils/wnumb_own'

import { getCompany } from '@/api/os/company'
import { listChildrenCompany, listCompanyAssetsAndAll } from '@/api/dw/datav'
import { listMaintainBillGbType, countTaskItemGbDate, getAssetMaintainRadar, getSupplierMaintainRadar } from '@/api/dw/maintain'

export default {
  data () {
    return {
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,

      chooseCompanyId: '0',
      chooseFeature: '1', // 查询类型
      chooseAssetId: '0',
      chooseSupplierId: '0', // 供应商清单
      chooseTableId: null,

      childCompanyList: [],
      companyAssetList: [],
      supplierList: [],
      dataSourcePie: null,

      maintainBillGbTypeData: [], // 运维成本数据

      countTaskItemGbDateColumn: [
        { title: '作业日期', key: 'actionDate' },
        { title: '人数', width: 60, align: 'center', key: 'userNumber' },
        { title: '作业量', width: 60, align: 'center', key: 'itemNumber' },
        {
          title: '及时完成率',
          align: 'right',
          render: (h, params) => {
            return h('span', params.row.onTimeRate + ' %')
          }
        },
        {
          title: '合格率',
          align: 'right',
          render: (h, params) => {
            return h('span', params.row.onTestRate + ' %')
          }
        }
      ],
      countTaskItemGbDateData: [], // 履约成本清单
      showArray: [],
      currentPageSize: 6,
      currentPageNumber: 1
    }
  },
  mounted () {
    this.chooseCompanyId = this.companyId
    this.loadCompanyList()
    this.loadCompanyAssetList()
    this.loadSupplierList()
    this.loadMaintainBillGbType()
    this.loadCountTaskItemGbDate()
    this.loadReportData()
  },
  methods: {
    loadCompanyList () {
      if (this.companyId !== '0') { // 表示代理商
        getCompany({ companyId: this.companyId }).then(res => {
          if (res && !res.errcode) {
            this.childCompanyList = [
              { id: this.companyId, name: res.name }
            ]
          }
        })
      } else {
        const postData1 = {
          endDate: this.searchSchedule.endDate,
          loginCompanyId: this.loginCompanyId,
          publisherId: this.publisherId,
          startDate: this.searchSchedule.startDate,
          type: 2 // 2代理商;3运维公司
        }
        listChildrenCompany(postData1).then(res => {
          this.childCompanyList = res
        })
      }
    },
    loadCompanyAssetList () {
      const postData2 = {
        publisherId: this.publisherId,
        companyId: this.chooseCompanyId
      }
      listCompanyAssetsAndAll(postData2).then(res => {
        this.companyAssetList = res
      })
    },
    loadSupplierList () {
      const postData1 = {
        endDate: this.searchSchedule.endDate,
        loginCompanyId: this.loginCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        type: 3 // 2代理商;3运维公司
      }
      listChildrenCompany(postData1).then(res => {
        this.supplierList = res
      })
    },
    loadMaintainBillGbType () {
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        supplierId: this.chooseSupplierId,
        companyId: this.chooseCompanyId
      }

      listMaintainBillGbType(queryModel).then(res => {
        this.maintainBillGbTypeData = res
      })
    },
    loadCountTaskItemGbDate () {
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        supplierId: this.chooseSupplierId,
        companyId: this.chooseCompanyId,
        assetId: this.chooseAssetId
      }

      this.countTaskItemGbDateData = []
      this.chooseTableId = null
      this.$store.commit('set_situation_gbId', this.chooseTableId)
      countTaskItemGbDate(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    loadReportData () {
      let queryModel = null
      if (this.chooseFeature === '1') {
        queryModel = {
          startDate: this.searchSchedule.startDate,
          endDate: this.searchSchedule.endDate,
          publisherId: this.publisherId,
          assetId: this.chooseAssetId
        }
        getAssetMaintainRadar(queryModel).then(res => {
          this.initRadarChart(res)
        })
      } else {
        queryModel = {
          startDate: this.searchSchedule.startDate,
          endDate: this.searchSchedule.endDate,
          publisherId: this.publisherId,
          companyId: this.chooseCompanyId,
          supplierId: this.chooseSupplierId
        }
        getSupplierMaintainRadar(queryModel).then(res => {
          this.initRadarChart(res)
        })
      }
    },
    initRadarChart (reportData) {
      const labels = []
      const ownValue = []
      const seriesName = reportData[0].s
      reportData.forEach(element => {
        // 添加标注信息
        labels.push({ name: element.dim, max: 100 })
        ownValue.push(parseFloat(element.value))
      })

      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('maintenanceRadarReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('maintenanceRadarReport'))

          const option = {
            color: ['#FA8B59', '#4285F4'],
            tooltip: {
              trigger: 'axis',
              position: 'bottom'
            },
            radar: [
              {
                indicator: labels,
                radius: 70,
                axisName: {
                  fontSize: '10',
                  padding: [0, -6]
                }
              }
            ],
            series: [
              {
                type: 'radar',
                tooltip: {
                  trigger: 'item'
                },
                symbolSize: 3,
                areaStyle: {},
                data: [
                  {
                    value: ownValue,
                    name: seriesName
                  }
                ]
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
        }
      })
    },
    rowClassName (row, index) {
      return row.actionDate === this.chooseTableId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow (params, index) {
      this.chooseTableId = (this.chooseTableId === params.actionDate) ? null : params.actionDate
      this.$store.commit('set_situation_gbId', this.chooseTableId)
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.countTaskItemGbDateData = newArray
    },
    formatMoney (money) {
      return toMoney(money)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadReportData()
        this.loadMaintainBillGbType()
        this.loadCountTaskItemGbDate()
      }
    },
    chooseCompanyId (val) {
      this.$store.commit('set_situation_companyId', val)
      this.loadReportData()
      this.loadMaintainBillGbType()
      this.loadCountTaskItemGbDate()
    },
    chooseAssetId (val) {
      this.$store.commit('set_situation_assetId', val)
      this.loadReportData()
      this.loadCountTaskItemGbDate()
    },
    chooseSupplierId (val) {
      this.loadReportData()
      this.loadMaintainBillGbType()
      this.loadCountTaskItemGbDate()
    },
    chooseFeature (val) { // 切换查询类型
      if (val === '1') {
        if (this.chooseSupplierId !== '0') {
          this.chooseSupplierId = '0'
        } else {
          this.loadReportData()
        }
      } else {
        if (this.chooseAssetId !== '0') {
          this.chooseAssetId = '0'
        } else {
          this.loadReportData()
        }
      }
    }
  }
}
</script>

<style>
.maintenanceTable .ivu-table-cell{
  padding-left: 2px;
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
