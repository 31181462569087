import request from '@/utils/requestV2'

// 合同销售额--分组统计
export function countContractAmountGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/countContractAmountGb',
    method: 'get',
    params: data
  })
}
// 合同销售额--分组统计--按产品类型
export function countContractAmountByUsePriceGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/countContractAmountByUsePriceGb',
    method: 'get',
    params: data
  })
}
// 合同销售的刊例价及资源块天--分组统计
export function countContractUsePriceGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/countContractUsePriceGb',
    method: 'get',
    params: data
  })
}
// 在刊合同数及销售额--分组统计
export function countOnlineContractAmountGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/countOnlineContractAmountGb',
    method: 'get',
    timeout: 200000,
    params: data
  })
}
// 在刊合同的去化率--按合同类型统计
export function countOnlineContractResourceRate (data) {
  return request({
    url: '/ooh-dw/v1/datav/countOnlineContractResourceRate',
    timeout: 200000,
    method: 'get',
    params: data
  })
}
// 查询公司拥有的子公司
export function listChildrenCompany (data) {
  return request({
    url: '/ooh-dw/v1/datav/listChildrenCompany',
    method: 'get',
    params: data
  })
}
// 根据业主的ID查询拥有的线路集合
export function listCompanyAssetsAndAll (data) {
  return request({
    url: '/ooh-dw/v1/datav/listCompanyAssetsAndAll',
    method: 'get',
    params: data
  })
}
// 根据业主的ID查询拥有的线路集合
export function listCompanyAssets (data) {
  return request({
    url: '/ooh-dw/v1/datav/listCompanyAssets',
    method: 'get',
    params: data
  })
}
// 获取业主当年截至到当前的客流量
export function getPublisherPassengerFlow (data) {
  return request({
    url: '/ooh-dw/v1/datav/getPublisherPassengerFlow',
    method: 'get',
    params: data
  })
}
// 客流量数据更新最新的日期
export function getLastUpdateDate (data) {
  return request({
    url: '/ooh-dw/v1/datav/getLastUpdateDate',
    method: 'get',
    params: data
  })
}
// 获取业主时间段内不同类型合同金额、数量和折扣率
export function countContractAmount (data) {
  return request({
    url: '/ooh-dw/v1/datav/countContractAmount',
    method: 'get',
    params: data
  })
}
// 统计包含附件的合同数（管理员上传）
export function countHaveFileNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/countHaveFileNumber',
    method: 'get',
    params: data
  })
}
// 获取业主时间段内不同类型合同（1商业，2公益，3小微）签约的合同列表
export function listPublisherContractsByOrderType (data) {
  return request({
    url: '/ooh-dw/v1/datav/listPublisherContractsByOrderType',
    method: 'get',
    params: data
  })
}
// 获取业主近期审批通过的合同
export function listContracts (data) {
  return request({
    url: '/ooh-dw/v1/datav/listContracts',
    method: 'get',
    params: data
  })
}
// 合同额，合同数及订单数按日期的比较接口
export function listContractNumberAndAmountGroupByDate (data) {
  return request({
    url: '/ooh-dw/v1/datav/listContractNumberAndAmountGroupByDate',
    method: 'get',
    params: data
  })
}
// 通过主键获取合同
export function getContractById (data) {
  return request({
    url: '/ooh-dw/v1/datav/getContractById',
    method: 'get',
    params: data
  })
}
// 获取广告主签约的合同折扣率
export function listAdvertiserContractDiscountRate (data) {
  return request({
    url: '/ooh-dw/v1/datav/listAdvertiserContractDiscountRate',
    method: 'get',
    params: data
  })
}
// 获取广告主签约的合同数
export function listAdvertiserContractCode (data) {
  return request({
    url: '/ooh-dw/v1/datav/listAdvertiserContractCode',
    method: 'get',
    params: data
  })
}
// 获取广告主历史投放TOP站点
export function listAdvertiserBuyTopStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/listAdvertiserBuyTopStation',
    method: 'get',
    params: data
  })
}
// 获取广告主投放的合同额时间折线表
export function listAdvertiserAddUpAmount (data) {
  return request({
    url: '/ooh-dw/v1/datav/listAdvertiserAddUpAmount',
    method: 'get',
    params: data
  })
}
// 统计合同的数量
export function countContractNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/countContractNumber',
    method: 'get',
    params: data
  })
}
// 获取业主时间段内的回款率-包含应回款和实际回款
export function getPublisherDebtRate (data) {
  return request({
    url: '/ooh-dw/v1/datav/getPublisherDebtRate',
    method: 'get',
    params: data
  })
}
// 获取业主时间段内不同类型合同（1商业，2小微，3公益）签约的合同金额
export function countPublisherContractAmountByOrderType (data) {
  return request({
    url: '/ooh-dw/v1/datav/countPublisherContractAmountByOrderType',
    method: 'get',
    params: data
  })
}
// 统计执行额按月分组(线路或站点必传一个)
export function countExecuteAmountGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/countExecuteAmountGb',
    method: 'get',
    params: data
  })
}
// 查询公司拥有的子公司
export function listChildrenCompanyAndSelf (data) {
  return request({
    url: '/ooh-dw/v1/datav/listChildrenCompanyAndSelf',
    method: 'get',
    params: data
  })
}
// 查询时间内的销售额(细分发布费、服务费)-分组统计
export function listContractAmountGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/listContractAmountGb',
    method: 'get',
    params: data
  })
}
// 查询最新的日期的客流量 按站点分组
export function listLastFlowGbStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/listLastFlowGbStation',
    method: 'get',
    params: data
  })
}
// 合同签约额的站点热力图
export function countContractAmountHeatMap (data) {
  return request({
    url: '/ooh-dw/v1/datav/countContractAmountHeatMap',
    method: 'get',
    params: data
  })
}
// 合同执行额的站点热力图
export function countExecuteAmountHeatMap (data) {
  return request({
    url: '/ooh-dw/v1/datav/countExecuteAmountHeatMap',
    method: 'get',
    params: data
  })
}
